.gallery {
	&__item {
		display: flex;
		flex-direction: column;
		position: relative;
	}

	&__link {
		line-height: 0;
		display: block;
	}

	&__image {
		width: 100%;
		background-color: #eeeeee;
	}
}