/*
* BASE DEPENDENCIES
*/
/*****************************************
* ECCO - FUNCTIONS
*****************************************/
/*****************************************
* ECCO - MIXINS
*****************************************/
/*
* SHARED
*/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*
* LAYOUT
*/
.page-module {
  margin-bottom: 3em;
}

.page__header {
  background-color: #002c45;
  color: #ffffff;
  padding: 2rem 0 22%;
  margin-bottom: -20%;
}

.page__header .text-module {
  margin-bottom: 0;
}

.main-heading {
  color: inherit;
  font-size: 1.875rem;
}

/*
.content {
	max-width: 72ch;
	margin-left: auto;
	margin-right: auto;
}
 */
/*
.nestedset {
	span {
		display: inline-block;
		padding: 1em 0;
		margin: 0 2em;
		width: 6em;
		border: 1px rgb(175, 175, 175) solid;
		background: rgb(218, 218, 218);
		border-radius: 8px;
	}

	span[data-lft="1"] {
		border-width: 2px;
		font-weight: bold;
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 4em 0;
		text-align: center;
		overflow: hidden;
	}

	ul li {
		display: flex;
		flex-direction: column;
		margin: 0 0 1em;
		padding: 0;
		align-items: center;
		position: relative;
	}

	ul li li::before {
		content: "";
		position: absolute;
		left: 50%;
		bottom: 100%;
		border-right: 1px rgb(175, 175, 175) solid;
		width: 0;
		height: 2em;
	}

	ul li li::after {
		content: "";
		position: absolute;
		top: -2em;
		height: 0;
		border-bottom: 1px rgb(175, 175, 175) solid;
	}

	ul li li:first-child::after {
		left: 50%;
		right: 0;
	}

	ul li li:last-child::after {
		right: 50%;
		left: 0;
	}

	ul li li:first-child:last-child::after {
		content: normal;
	}

	ul li li:not(:first-child):not(:last-child)::after {
		right: 0;
		left: 0;
	}

	ul ul {
		position: relative;
	}

	ul ul::after {
		content: "";
		position: absolute;
		top: 0;
		left: 50%;
		height: 2em;
		width: 0;
		border-right: 1px rgb(175, 175, 175) solid;
	}

	li span {
		position: relative;
	}

	li > span::before,
	li > span::after {
		position: absolute;
		font-size: 12px;
	}

	li > span::before {
		right: 110%;
		color: red;
		content: attr(data-lft);

	}

	li > span::after {
		left: 110%;
		color: blue;
		content: attr(data-rgt);
	}

	ul ul {
		display: flex;
		flex-direction: row;
	}
} */
.masonry {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.masonry__item {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  position: relative;
  margin-bottom: 0;
  display: inline-flex;
  flex-direction: column;
}

.masonry__item {
  margin-bottom: 4vmax;
}

@media screen and (min-width: 450px) {
  .masonry {
    column-count: 2;
    column-gap: 4vmax;
  }
}

@media screen and (min-width: 1024px) {
  .masonry {
    column-count: 3;
    column-gap: 3vmax;
  }
  .masonry__item {
    margin-bottom: 3vmax;
  }
}

@media screen and (min-width: 1600px) {
  .masonry {
    column-gap: 45px;
  }
  .masonry__item {
    margin-bottom: 45px;
  }
}

.gallery__item {
  display: flex;
  flex-direction: column;
  position: relative;
}

.gallery__link {
  line-height: 0;
  display: block;
}

.gallery__image {
  width: 100%;
  background-color: #eeeeee;
}

.swipeview {
  display: none;
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30999;
  background: rgba(56, 52, 51, 0.85);
  backdrop-filter: blur(5px);
  scroll-behavior: auto;
  touch-action: manipulation;
}

.swipeview[data-is-open="true"] {
  display: block;
}

.swipeview__list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  --edge-transition: none;
  --edge-transform: translateX(0%);
}

.swipeview__item {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  transition: var(--edge-transition);
  transform: var(--edge-transform);
}

.swipeview__image {
  user-select: none;
  pointer-events: none;
  object-fit: contain;
  max-height: 100%;
}

.swipeview__button {
  position: fixed;
  box-shadow: none;
  outline: 0;
  z-index: 2;
  width: 40px;
  height: 40px;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border-radius: 0;
  transition: background-color .15s ease-in;
  background-color: rgba(56, 52, 51, 0.85);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: none;
  background-size: 65%;
}

.swipeview__button:hover, .swipeview__button:focus {
  outline: 0;
  background-color: #002c45;
}

@media screen and (min-width: 800px) {
  .swipeview__button {
    width: 70px;
    height: 70px;
  }
}

.swipeview__button[disabled] {
  visibility: hidden;
}

.swipeview__button.-exit-button {
  right: 0;
  top: 0;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' width='40' height='40'%3E%3Cpath fill='%23f6f2ef' d='M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z'/%3E%3C/svg%3E");
}

.swipeview__button.-previous-button {
  left: 0;
  top: 50%;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' width='40' height='40'%3E%3Cpath fill='%23f6f2ef' d='M8.502 20c-.003-.259.092-.519.289-.716l19.008-19.007c.389-.389 1.02-.389 1.408 0s.389 1.019 0 1.407l-18.315 18.316 18.315 18.315c.389.389.389 1.019 0 1.407s-1.02.389-1.408 0l-19.008-19.006c-.197-.197-.292-.457-.289-.716z'/%3E%3C/svg%3E");
}

.swipeview__button.-next-button {
  right: 0;
  top: 50%;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' width='40' height='40'%3E%3Cpath fill='%23f6f2ef' d='M31.209 20.716l-19.008 19.007c-.389.389-1.019.389-1.407 0s-.389-1.019 0-1.407l18.314-18.316-18.315-18.315c-.389-.389-.389-1.019 0-1.407s1.019-.389 1.407 0l19.008 19.007c.197.197.292.457.289.716.004.258-.091.518-.288.715z'/%3E%3C/svg%3E");
}
