

.page-module {
	margin-bottom: 3em;
}

.page__header {
	background-color: $color-brand;
	color: #ffffff;
	padding: 2rem 0 22%;
	margin-bottom: -20%;

	.text-module {
		margin-bottom: 0;
	}
}

.main-heading {
	color: inherit;
	font-size: rem(30px);
}

/*
.content {
	max-width: 72ch;
	margin-left: auto;
	margin-right: auto;
}
 */

/*
.nestedset {
	span {
		display: inline-block;
		padding: 1em 0;
		margin: 0 2em;
		width: 6em;
		border: 1px rgb(175, 175, 175) solid;
		background: rgb(218, 218, 218);
		border-radius: 8px;
	}

	span[data-lft="1"] {
		border-width: 2px;
		font-weight: bold;
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 4em 0;
		text-align: center;
		overflow: hidden;
	}

	ul li {
		display: flex;
		flex-direction: column;
		margin: 0 0 1em;
		padding: 0;
		align-items: center;
		position: relative;
	}

	ul li li::before {
		content: "";
		position: absolute;
		left: 50%;
		bottom: 100%;
		border-right: 1px rgb(175, 175, 175) solid;
		width: 0;
		height: 2em;
	}

	ul li li::after {
		content: "";
		position: absolute;
		top: -2em;
		height: 0;
		border-bottom: 1px rgb(175, 175, 175) solid;
	}

	ul li li:first-child::after {
		left: 50%;
		right: 0;
	}

	ul li li:last-child::after {
		right: 50%;
		left: 0;
	}

	ul li li:first-child:last-child::after {
		content: normal;
	}

	ul li li:not(:first-child):not(:last-child)::after {
		right: 0;
		left: 0;
	}

	ul ul {
		position: relative;
	}

	ul ul::after {
		content: "";
		position: absolute;
		top: 0;
		left: 50%;
		height: 2em;
		width: 0;
		border-right: 1px rgb(175, 175, 175) solid;
	}

	li span {
		position: relative;
	}

	li > span::before,
	li > span::after {
		position: absolute;
		font-size: 12px;
	}

	li > span::before {
		right: 110%;
		color: red;
		content: attr(data-lft);

	}

	li > span::after {
		left: 110%;
		color: blue;
		content: attr(data-rgt);
	}

	ul ul {
		display: flex;
		flex-direction: row;
	}
} */