
.swipeview {
	display: none;
	position: fixed;
	overflow: hidden;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 30999;
	background: rgba($color-dark,.85);
	backdrop-filter: blur(5px);
	scroll-behavior: auto;
	touch-action: manipulation;

	&[data-is-open="true"] {
		display: block;
	}

	&__list {
		padding: 0;
		margin: 0;
		list-style-type: none;
		display: flex;
		flex-wrap: nowrap;
		height: 100vh;
		--edge-transition: none;
		--edge-transform: translateX(0%);
	}

	&__item {
		width: 100vw;
		height: 100vh;
		display: flex;
		flex-shrink: 0;
		flex-grow: 0;
		justify-content: center;
		align-items: center;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: contain;
		transition: var(--edge-transition);
		transform: var(--edge-transform);
	}

	&__image {
		user-select: none;
		pointer-events: none;
		object-fit: contain;
		max-height: 100%;
	}

	&__button {
		position: fixed;
		box-shadow: none;
		outline: 0;
		z-index: 2;
		width: 40px;
		height: 40px;
		-webkit-appearance: none;
		-moz-appearance: none;
		cursor: pointer;
		padding: 0;
		margin: 0;
		border-radius: 0;
		transition: background-color .15s ease-in;
		background: {
			color: rgba($color-dark, .85);
			repeat: no-repeat;
			position: 50% 50%;
			image: none;
			size: 65%;
		}

		&:hover,
		&:focus {
			outline: 0;
			background-color: $color-brand;
		}

		@include min(800px) {
			width: 70px;
			height: 70px;
		}
	}

	&__button[disabled] {
		visibility: hidden;
	}

	&__button.-exit-button {
		right: 0;
		top: 0;
		background-image: encode-svg('<svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" width="40" height="40"><path fill="#{$color-light}" d="M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z"/></svg>');
	}

	&__button.-previous-button {
		left: 0;
		top: 50%;
		background-image: encode-svg('<svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" width="40" height="40"><path fill="#{$color-light}" d="M8.502 20c-.003-.259.092-.519.289-.716l19.008-19.007c.389-.389 1.02-.389 1.408 0s.389 1.019 0 1.407l-18.315 18.316 18.315 18.315c.389.389.389 1.019 0 1.407s-1.02.389-1.408 0l-19.008-19.006c-.197-.197-.292-.457-.289-.716z"/></svg>');
	}

	&__button.-next-button {
		right: 0;
		top: 50%;
		background-image: encode-svg('<svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" width="40" height="40"><path fill="#{$color-light}" d="M31.209 20.716l-19.008 19.007c-.389.389-1.019.389-1.407 0s-.389-1.019 0-1.407l18.314-18.316-18.315-18.315c-.389-.389-.389-1.019 0-1.407s1.019-.389 1.407 0l19.008 19.007c.197.197.292.457.289.716.004.258-.091.518-.288.715z"/></svg>');
	}
}